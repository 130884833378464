import { WebMessenger, setUp } from './web-messenger';
import { IFRAME_ID } from '../../shared/js/constants/ids';

// Ensure iframe has not yet been injected
if (!document.getElementById(IFRAME_ID)) {
    setUp();

    if (window.__onWebMessengerHostReady__) {
        window.__onWebMessengerHostReady__(WebMessenger);
    } else if (GLOBAL_VAR_NAME) {
        // Script loader wasn't found, host bundle is being used on its own.
        global[GLOBAL_VAR_NAME] = WebMessenger;
    }
}
