import enquire from 'enquire.js';

import { SCREEN_SIZES } from '../../../shared/js/constants/sizes';
import { generateMediaQuery } from './dom';

const sizes = ['lg', 'md', 'sm', 'xs'];

function applyRules(enquireFunction) {
    for (let i = 0; i < sizes.length; i++) {
        const size = sizes[i];

        let rules = SCREEN_SIZES[size];
        // polyfill for Array.isArray
        if (Object.prototype.toString.call(rules) !== '[object Array]') {
            rules = [rules];
        }

        for (let j = 0; j < rules.length; j++) {
            const rule = rules[j];
            enquireFunction({ rule, size });
        }
    }
}

export function init(iframe) {
    applyRules(({ rule, size }) => {
        enquire.register(generateMediaQuery(rule), () => {
            iframe.contentWindow.postMessage(
                {
                    type: 'sizeChange',
                    value: size,
                },
                `${location.protocol}//${location.host}`,
            );
        });
    });
}

export function unregister() {
    applyRules(({ rule }) => {
        enquire.unregister(generateMediaQuery(rule));
    });
}
