import hostStyles from '../stylesheets/iframe.less';
import { waitForPage } from './utils/dom';
import * as enquire from './utils/enquire';
import { IFRAME_ID } from '../../shared/js/constants/ids';

export const WebMessenger = {};

let Lib;
let iframe;

let isEmbedded;
let embeddedContainer;

let pendingInitChains = [];
let pendingOnCalls = [];
let pendingInitCall;

const isCrawler = /lebo|awle|pide|obo|rawli|dsbo/i.test(navigator.userAgent);
const isPhantomJS = /PhantomJS/.test(navigator.userAgent) && process.env.NODE_ENV !== 'test';

const LIB_FUNCS = [
    'init',
    'login',
    'on',
    'off',
    'logout',
    'sendMessage',
    'triggerPostback',
    'updateUser',
    'getConversation',
    'getConversationById',
    'getConversations',
    'getUser',
    'open',
    'close',
    'isOpened',
    'loadConversation',
    'startConversation',
    'setDelegate',
    'markAllAsRead',
    'showNotificationChannelPrompt',
    'setPredefinedMessage',
    'startTyping',
    'stopTyping',
    'clearClient'
];

if (isCrawler && !IS_BRANDED) {
    const el = document.createElement('a');
    el.href = 'https://smooch.io/live-web-chat/?utm_source=widget';
    el.text = 'Powered by Zendesk Sunshine';

    waitForPage(() => {
        document.body.appendChild(el);
    });
}

function injectHostStyles() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = HOST_CSS_URL;
    document.body.appendChild(link);
}

function onWebMessengerReady(_Lib) {
    window.__onWebMessengerFrameReady__ = function () {};
    Lib = _Lib;
    if (!isEmbedded) {
        enquire.init(iframe);
    }

    for (let func = LIB_FUNCS[0], i = 0; i < LIB_FUNCS.length; func = LIB_FUNCS[++i]) {
        WebMessenger[func] = Lib[func];
    }

    if (pendingOnCalls) {
        for (let call = pendingOnCalls[0], i = 0; i < pendingOnCalls.length; call = pendingOnCalls[++i]) {
            Lib.on(...call.args);
        }
        pendingOnCalls = undefined;
    }

    if (pendingInitCall) {
        let promise = Lib.init(...pendingInitCall);
        pendingInitCall = undefined;

        for (let call = pendingInitChains[0], i = 0; i < pendingInitChains.length; call = pendingInitChains[++i]) {
            if (call.type === 'then') {
                promise = promise.then(call.next);
            } else {
                promise = promise.catch(call.next);
            }
        }

        pendingInitChains = [];
    }
}

const _getIFrameDoc = (iframe) => {
    return iframe.contentWindow && iframe.contentWindow.document;
};

function injectFrame() {
    const REACT_DEBUG_SCRIPT =
        process.env.NODE_ENV === 'development'
            ? '<script nonce=reactdevtoolglobalhook> __REACT_DEVTOOLS_GLOBAL_HOOK__ = parent.__REACT_DEVTOOLS_GLOBAL_HOOK__ </script>'
            : '';

    if (!iframe) {
        let loadCheck = null;
        let loaded = false;

        iframe = document.createElement('iframe');

        iframe.id = IFRAME_ID;
        iframe.title = `${IFRAME_TITLE}`;
        iframe.style.cssText = 'border:none; overflow:hidden;';
        iframe.allowFullscreen = true;
        iframe.allowTransparency = true;

        iframe.className = hostStyles.iframe;

        const _writeToIFrame = () => {
            loaded = true;
            clearInterval(loadCheck);
            delete iframe.onload;

            const iframeDoc = _getIFrameDoc(iframe);
            iframeDoc.open();
            iframeDoc.write(`
                    <!DOCTYPE html>
                    <html>
                        <head>
                            ${REACT_DEBUG_SCRIPT}
                            <link rel="stylesheet" href="${FRAME_CSS_URL}" type="text/css" />
                            <script src="${FRAME_JS_URL}" defer crossorigin="anonymous"></script>
                        </head>
                        <body>
                            <div id="mount"></div>
                        </body>
                    </html>
                    `);
            iframeDoc.close();
        };

        // Some browsers seem to not trigger onload
        loadCheck = setInterval(() => {
            const iframeDoc = _getIFrameDoc(iframe);
            if (
                !loaded &&
                iframeDoc &&
                (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive')
            ) {
                _writeToIFrame();
            }
        }, 1000);
    }

    if (isEmbedded) {
        if (embeddedContainer) {
            embeddedContainer.appendChild(iframe);
            embeddedContainer = undefined;
        }
    } else {
        document.body.appendChild(iframe);
    }
}

const Skeleton = {
    VERSION,
    on(...args) {
        if (!pendingOnCalls) {
            pendingOnCalls = [];
        }

        pendingOnCalls.push({
            args,
        });
    },
    init(...args) {
        pendingInitCall = args;
        isEmbedded = args.length > 0 && !!args[0].embedded;

        if (!isCrawler && !isPhantomJS) {
            waitForPage(() => {
                injectHostStyles();
                injectFrame();
            });
        }

        const fakePromise = {
            then: (next) => {
                pendingInitChains.push({
                    type: 'then',
                    next,
                });
                return fakePromise;
            },
            catch: (next) => {
                pendingInitChains.push({
                    type: 'catch',
                    next,
                });
                return fakePromise;
            },
        };

        return fakePromise;
    },
    render(container) {
        if (iframe) {
            container.appendChild(iframe);
        } else {
            embeddedContainer = container;
        }
    },
    destroy() {
        if (Lib) {
            Lib.destroy();
            iframe.remove ? iframe.remove() : iframe.parentNode.removeChild(iframe);
            enquire.unregister();
            setUp();
        }
    },
};

export function setUp() {
    if (document.getElementById(IFRAME_ID)) {
        // Iframe has already been injected, destroy() was not yet called. Loader may have been double invoked
        return;
    }

    Lib = undefined;
    iframe = undefined;
    window.__onWebMessengerFrameReady__ = onWebMessengerReady;
    for (let func = LIB_FUNCS[0], i = 0; i < LIB_FUNCS.length; func = LIB_FUNCS[++i]) {
        if (WebMessenger[func]) {
            delete WebMessenger[func];
        }
    }
    Object.assign(WebMessenger, Skeleton);
}
