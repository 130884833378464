export const SCREEN_SIZES = {
    lg: {
        minHeight: 668,
        minWidth: 1200,
    },
    md: [
        {
            minHeight: 508,
            minWidth: 768,
            maxWidth: 1199,
        },
        {
            minHeight: 508,
            maxHeight: 667,
            minWidth: 768,
        },
    ],
    sm: {
        maxHeight: 507,
        minWidth: 768,
    },
    xs: {
        maxWidth: 767,
    },
};
